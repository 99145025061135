import Autolinker from 'autolinker';

export function brToNewLines(string) {
  if (!string) {
    return '';
  }
  return string.replace(/<br\s*[/]?>/gi, '\n');
}

export function newLinesToBr(string) {
  if (!string) {
    return '';
  }
  return string.split('\n').join('<br />');
}

const encodeEntitiesRegex = /[&"'<>\n]/g;
const encodeEntitiesMap = {
  '&': '&amp;',
  "'": '&apos;',
  '"': '&quot;',
  '<': '&lt;',
  '>': '&gt;',
  '\n': '<br>',
};

const mentionsRegex = /\[((id|club)\d+)\|(.+?)\]/gi;
const mentionsSimpleRegex = /\[(.+?)\|(.+?)\]/gi;
const mentionTemp = '<a class="Link vkuiLink Link--mention" href="https://vk.com/{0}">{1}</a>';

export function replaceSpecialSymbols(string) {
  if (!string) {
    return '';
  }
  return string.replace(/<</g, '«').replace(/>>/g, '»').replace(/ --/g, ' —').replace(/-- /g, '— ');
}

export function escapeHtmlEntities(string, needBr = false) {
  return string.replace(encodeEntitiesRegex, (entity) => {
    if (!needBr && entity === '\n') {
      return entity;
    }
    return encodeEntitiesMap[entity];
  });
}

const autolinker = new Autolinker({
  urls: {
    schemeMatches: true,
    wwwMatches: true,
    tldMatches: true,
  },
  email: false,
  phone: false,
  mention: false,
  hashtag: false,
  stripPrefix: true,
  stripTrailingSlash: true,
  newWindow: true,
  truncate: {
    length: 40,
    location: 'end',
  },
  className: 'Link vkuiLink',
  replaceFn: linkReplacer,
});

function linkReplacer(match) {
  switch (match.getType()) {
    case 'url': {
      const tag = match.buildTag();
      const url = match.url;
      if (/^\/[^/]/.test(url) || /^(https?:\/\/)?([a-z0-9_\-.]+\.)?vk.(com|ru|me)(\/.*)?/.test(url)) {
        return true;
      }
      let encoded = url;
      try {
        encoded = encodeURIComponent(url);
      // eslint-disable-next-line no-empty
      } catch (e) {}
      tag.setAttr('href', `https://vk.com/away.php?to=${encoded}&utf=1`);
      tag.setAttr('rel', 'noopener');
      return tag;
    }
    default:
      return match.buildTag();
  }
}

/**
 * Экранирует и форматирует строку
 */
export function escapeAndFormatString(string, options = {}) {
  if (!string) {
    return '';
  }

  string = replaceSpecialSymbols(string);
  string = escapeHtmlEntities(string, options.breaks);

  if (options.links) {
    string = autolinker.link(string);
  }

  if (options.mentions) {
    string = string.replace(mentionsRegex, (_match, id, _, name) => {
      return mentionTemp.replace('{0}', id).replace('{1}', name);
    });
  } else {
    string = string.replace(mentionsSimpleRegex, (_a, _b, name) => name);
  }

  return string;
}

import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Checkbox from '@vkontakte/vkui/dist/components/Checkbox/Checkbox';
import Separator from '@vkontakte/vkui/dist/components/Separator/Separator';
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import { Icon28HelpOutline, Icon28MoneyCircleOutline, Icon28DonateOutline } from '@vkontakte/icons';
import { FireEvent } from '../services/_functions';

import '../../styles/quest-header.scss';

const QuestHeader = ({ progressWidth, value, maxValue, earned, place,
  manager, questType, autoToggleQuest, setAutoToggleQuest }) => {
  const rightQuestType = questType &&
        (questType === 'choose-option-image' || questType === 'choose-option' || questType === 'choose-image');

  return (
    <>
      <FixedLayout vertical="top" className="quest-header">
        <Div className="quest-header__progress" style={progressWidth ? { width: progressWidth } : {}}>
          <div className="quest-header__progress__label">
            {value} из {maxValue}
          </div>
          <div className="quest-header__progress__line">
            <span style={{ width: `${(value / maxValue) * 100}%` }} />
          </div>
        </Div>
        <Div className="quest-header__reward">
          <div className="quest-header__reward__item">
            <Icon28MoneyCircleOutline fill="var(--accent)" />
            <span>{earned} заработано</span>
          </div>
          <div className="quest-header__reward__item">
            <Icon28DonateOutline fill="var(--accent)" />
            <span>{place} место</span>
          </div>
          {manager.vk_id && (
            <div
              className="quest-header__reward__item"
              onClick={() => FireEvent(`https://vk.com/id${manager.vk_id}`)}
            >
              <Icon28HelpOutline width={30} height={30} fill="var(--accent)" />
            </div>
          )}
        </Div>
        {rightQuestType && (
          <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Checkbox
              style={{ paddingTop: 6 }}
              checked={autoToggleQuest}
              onChange={() => setAutoToggleQuest(!autoToggleQuest)}
            >
              Автоматически переключать на следующее задание
            </Checkbox>
          </Div>
        )}
        <Separator />
      </FixedLayout>
      <div style={{ height: 96 + (rightQuestType ? 80 : 30) }} />
    </>
  );
};

QuestHeader.propTypes = {
  progressWidth: PropTypes.string,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  earned: PropTypes.number.isRequired,
  place: PropTypes.any.isRequired,
  manager: PropTypes.shape({
    name: PropTypes.string,
    vk_id: PropTypes.string,
  }),
  questType: PropTypes.string.isRequired,
  autoToggleQuest: PropTypes.bool.isRequired,
  setAutoToggleQuest: PropTypes.func.isRequired,
};

QuestHeader.defaultProps = {
  progressWidth: null,
  manager: {
    name: 'Хост',
    vk_id: '160558926',
  },
};

export default QuestHeader;

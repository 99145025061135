import React from 'react';
import PropTypes from 'prop-types';

import HeaderButton from '@vkontakte/vkui/dist/components/HeaderButton/HeaderButton';
import withPlatform from '@vkontakte/vkui/dist/hoc/withPlatform';
import { Icon28ChevronBack, Icon24Back } from '@vkontakte/icons';
import { IOS } from '@vkontakte/vkui';

const BackButton = ({ platform, action }) => (
  <HeaderButton onClick={action}>
    {platform === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
  </HeaderButton>
);

BackButton.propTypes = {
  platform: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default withPlatform(BackButton);

import vkConnect from '@vkontakte/vk-connect';
import bridge from '@vkontakte/vk-bridge';
import mocks from './mocks';

const VK_API_VERSION = '5.103';
const REQUEST_TIME = 5000;
const ENDPOINT = process.env.REACT_APP_BACKEND_URL || 'https://crm-ml.vk-admin.com';

const makeRequest = (method, params = []) => new Promise((resolve) => {
  const form = new FormData();
  Object.keys(params).map(x => form.append(x, params[x]));
  form.append('url', window.location.href);

  fetch(`${ENDPOINT}/${method}`, {
    method: 'POST',
    'Content-Type': 'application/json',
    body: form,
  })
    .then((response) => response.json())
    .then(({ result, ...rest }) => {
      if (result === 'success') {
        return resolve(rest);
      }
      return resolve(null);
    })
    .catch(() => resolve(null));
});

export const getTasks = () => new Promise(async (resolve) => {
  if (window.is_dev) {
    return setTimeout(() => resolve(mocks.tasks), REQUEST_TIME);
  }
  const result = await makeRequest('get-tasks');
  if (result) {
    return resolve(result.task_list);
  }
  return resolve([]);
});

export const getQuestsByTask = (taskId) => new Promise(async (resolve) => {
  if (window.is_dev) {
    const quests = mocks.quests[taskId] || [];
    return setTimeout(() => resolve(quests), REQUEST_TIME);
  }

  try {
    const result = await makeRequest('get-quests-by-task', {
      task_id: taskId,
    });

    if (result) {
      return resolve(result.quest_list);
    }

    return resolve([]);
  } catch (e) {
    console.log(e, 'Ошибка запроса getQuestsByTask');
  }
});

export const solveTask = (taskId = -1, questId = -1, answer = '') => new Promise(async (resolve, reject) => {
  if (window.is_dev) {
    return setTimeout(() => resolve(), REQUEST_TIME);
  }
  const result = await makeRequest('save-result', {
    task_id: taskId,
    quest_id: questId,
    payload: JSON.stringify({ value: answer }),
  });

  if (result) {
    return resolve();
  }

  return reject();
});

export const getVkToken = () => new Promise((resolve) => {
  const rejectTimer = setTimeout(() => resolve('not_allowed'), REQUEST_TIME);
  vkConnect.sendPromise('VKWebAppGetAuthToken', {
    app_id: window.app_id,
    scope: '',
  })
    .then((response) => {
      resolve(response.access_token);
      clearTimeout(rejectTimer);
    })
    .catch(() => resolve('not_allowed'));
});

export const getRating = () => new Promise(async (resolve) => {
  const result = await makeRequest('get-user-static');
  if (result) {
    return resolve(result);
  }
  return resolve({});
});

export const getVkProfile = (accessToken, ids) => new Promise((resolve, reject) => {
  vkConnect.sendPromise('VKWebAppCallAPIMethod', {
    method: 'users.get',
    params: {
      access_token: accessToken,
      v: VK_API_VERSION,
      user_ids: ids.join(','),
      fields: 'photo_200',
    },
  })
    .then((response) => resolve(response.response))
    .catch(reject);
});

export const fullSizeImage = (images) => {
  return bridge.send('VKWebAppShowImages', {
    images,
    start_index: 0,
  });
};

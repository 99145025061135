import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import { loadQuests } from '../store/quests/actions';
import * as API from '../services/api';

import BackButton from '../components/BackButton';
import TaskStatistic from '../components/TaskStatistic';
import Rating from '../components/Rating';

import { daysToDate } from '../services/_functions';

import '../../styles/task-about.scss';

const TaskAbout = ({ id, navigator }) => {
  const task = useSelector((state) => state.tasks.list[state.tasks.active]);
  const me = useSelector((state) => state.user.me);
  const rating = useSelector((state) => state.user.rating);
  const dispatch = useDispatch();
  return (
    <Panel
      id={id}
      theme="white"
    >
      <PanelHeader
        left={<BackButton action={navigator.goBack} />}
      />
      <Div className="task-about">
        <div className="task-about__wrap">
          <div className="task-about__title">{task.title}</div>
          <div className="task-about__text">{task.description}</div>
          <div className="task-about__pay">
            <div className="task-about__pay-normal"><span>{task.price}</span> за задание</div>
            <div className="task-about__pay-maximum"><span>{task.max_price}</span> максимум</div>
          </div>
        </div>
      </Div>
      <TaskStatistic
        solved={task.statistic && task.statistic.solved_quests}
        total={task.statistic && task.statistic.total_quests}
        earned={task.statistic && task.statistic.earned}
        endTime={task.statistic ? daysToDate(task.statistic.deadline) : 0}
      />
      <Rating top={rating} me={{ ...me, ...task.user }} />
      <div style={{ height: '68px' }} />
      <FixedLayout
        vertical="bottom"
        className="quest-actions"
      >
        <Div>
          <Button
            size="xl"
            stretched
            onClick={() => {
              navigator.showLoader();
              API.getQuestsByTask(task.id)
                .then((quests) => {
                  if (quests.length > 0) {
                    dispatch(loadQuests(quests));
                    navigator.hideLoader();
                    navigator.goPage('quest');
                  } else {
                    navigator.showAlert({
                      title: 'Упс...',
                      description: 'Похоже вы сделали все доступные задания',
                    });
                  }
                });
            }}
          >
            Начать разметку
          </Button>
        </Div>
      </FixedLayout>
    </Panel>
  );
};

TaskAbout.propTypes = {
  id: PropTypes.string.isRequired,
  navigator: PropTypes.any,
};

export default TaskAbout;

export default [
  {
    id: 1,
    title: 'Котики',
    description: 'Жмакаем кнопочки, размечаем картинки с котиками',
    price: 2,
    maxPrice: 20,
    statistic: {
      from: 20,
      to: 120,
      earned: 20,
      endTime: 2,
    },
    rating: [
      {
        id: 19568187,
        points: 120,
      },
      {
        id: 4267535,
        points: 110,
      },
      {
        id: 96807259,
        points: 108,
      },
      {
        id: 12689109,
        points: 103,
      },
      {
        id: 434830,
        points: 93,
      },
    ],
    myPoints: 80,
    myPlace: 24,
  },
  {
    id: 2,
    title: 'Простая модерация фотографий',
    description: 'Жмакаем кнопочки, размечаем картинки с котиками',
    price: 5,
    maxPrice: 20,
    statistic: {
      from: 20,
      to: 120,
      earned: 20,
      endTime: 2,
    },
    rating: [
      {
        id: 19568187,
        points: 120,
      },
      {
        id: 4267535,
        points: 110,
      },
      {
        id: 96807259,
        points: 108,
      },
      {
        id: 12689109,
        points: 103,
      },
      {
        id: 434830,
        points: 93,
      },
    ],
    myPoints: 80,
    myPlace: 24,
  },
  {
    id: 3,
    title: 'Эстетическая привлекательность картинки',
    description: 'Жмакаем кнопочки, размечаем картинки с котиками',
    price: 1,
    maxPrice: 20,
    statistic: {
      from: 20,
      to: 120,
      earned: 20,
      endTime: 2,
    },
    rating: [
      {
        id: 19568187,
        points: 120,
      },
      {
        id: 4267535,
        points: 110,
      },
      {
        id: 96807259,
        points: 108,
      },
      {
        id: 12689109,
        points: 103,
      },
      {
        id: 434830,
        points: 93,
      },
    ],
    myPoints: 80,
    myPlace: 24,
  },
  {
    id: 4,
    title: 'Классификация сообщений из социальных сетей',
    description: 'Жмакаем кнопочки, размечаем картинки с котиками',
    price: 2,
    maxPrice: 20,
    statistic: {
      from: 20,
      to: 120,
      earned: 20,
      endTime: 2,
    },
    rating: [
      {
        id: 19568187,
        points: 120,
      },
      {
        id: 4267535,
        points: 110,
      },
      {
        id: 96807259,
        points: 108,
      },
      {
        id: 12689109,
        points: 103,
      },
      {
        id: 434830,
        points: 93,
      },
    ],
    myPoints: 80,
    myPlace: 24,
  },
];

import * as types from './actionTypes';

const initialState = {
  list: [],
  active: -1,
  loaded: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_TASKS: {
      return {
        ...state,
        list: action.tasks,
        loaded: true,
      };
    }
    case types.SELECT_TASK: {
      return {
        ...state,
        active: state.list.findIndex(item => item.id === action.taskId),
      };
    }
    case types.SOLVE_TASK: {
      return {
        ...state,
        list: state.list.map(x => (x.id === action.taskId ?
          {
            ...x,
            statistic: {
              ...x.statistic,
              solved_quests: x.statistic.solved_quests + 1,
              earned: x.statistic.earned + x.price,
            },
          } :
          x),
        ),
      };
    }
    case types.REDUCE_EARNED_POINTS: {
      return {
        ...state,
        list: state.list.map(x => (x.id === action.taskId ?
          {
            ...x,
            statistic: {
              ...x.statistic,
              solved_quests: x.statistic.solved_quests - 1,
              earned: x.statistic.earned - x.price,
            },
          } :
          x),
        ),
      };
    }
    default:
      return state;
  }
};

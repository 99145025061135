import vkConnect from '@vkontakte/vk-connect';
import * as API from '../../services/api';
import * as types from './actionTypes';

export const loadUser = () => async (dispatch) => {
  vkConnect.sendPromise('VKWebAppGetUserInfo', {})
    .then(async (user) => {
      const accessToken = await API.getVkToken();

      dispatch({
        type: types.LOAD_USER,
        user,
        access_token: accessToken,
      });
    })
    .finally(() => dispatch(loadRating()));
};

export const loadRating = () => async (dispatch) => {
  API.getRating()
    .then((response) => {
      dispatch({
        type: types.LOAD_USER_RATING,
        rating: response.rating || [],
        user: response.user || {},
      });
    });
};

export const addUsers = (users) => {
  return {
    type: types.ADD_USERS,
    users,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

import '../../styles/task-item.scss';

const TaskItem = ({ title, description, price, action }) => (
  <Div className="task-item">
    <div className="task-item__wrap">
      <div className="task-item__title">{title}</div>
      <div className="task-item__description">{description}</div>
      <div className="task-item__price">
        <span>{price}</span> за задание
      </div>
      <Button
        size="l"
        onClick={action}
      >
        Перейти к заданию
      </Button>
    </div>
  </Div>
);

TaskItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  action: PropTypes.func,
};

TaskItem.defaultProps = {
  title: '',
  description: '',
  price: 0,
  action: null,
};

export default TaskItem;

import React from 'react';
import PropTypes from 'prop-types';
import vkConnect from '@vkontakte/vk-connect';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import { Radio } from '@vkontakte/vkui';

import '../../styles/quest.scss';
import '../../styles/quest-image.scss';

const QuestChooseImage = ({ text, images, answer, handle }) => {
  return (
    <Div className="quest">
      <div className="quest__title">Выберите подходящий вариант</div>
      <div className="quest__subtitle">{text}</div>
      <div className="quest__box">
        <div className="quest__box-wrap quest__image-4">
          {images.map((image, k) => (
            <Radio
              name="radio"
              key={image.value}
              value={image.value}
              checked={answer === image.value}
              onChange={() => handle(image.value)}
            >
              <div
                className="quest-image"
                style={{ backgroundImage: `url('${image.image}')` }}
                onClick={(e) => {
                  e.preventDefault();
                  vkConnect.sendPromise('VKWebAppShowImages', {
                    images: images.map(img => img.image),
                    start_index: k,
                  });
                }}
              >
                {answer === image.value && <div className="quest-image__overlay" />}
              </div>
            </Radio>
          ))}
        </div>
      </div>
    </Div>
  );
};

QuestChooseImage.propTypes = {
  text: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  answer: PropTypes.any,
  handle: PropTypes.func.isRequired,
};

QuestChooseImage.defaultProps = {
  images: [
    {
      image: 'https://picsum.photos/200/300?random=1',
      value: '1',
    },
    {
      image: 'https://picsum.photos/200/300?random=2',
      value: '2',
    },
    {
      image: 'https://picsum.photos/200/300?random=3',
      value: '3',
    },
    {
      image: 'https://picsum.photos/200/300?random=4',
      value: '4',
    },
  ],
  answer: null,
};

export default QuestChooseImage;

import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import { Icon28DoneOutline, Icon28MoneyCircleOutline, Icon28RecentOutline } from '@vkontakte/icons';

import '../../styles/task-statistic.scss';
import { wordPad } from '../services/_functions';

const TaskStatistic = ({ solved, total, earned, endTime }) => (
  <Div className="task-statistic">
    <div className="task-statistic__wrap">
      <div className="task-statistic__title">Статистика</div>
      <div className="task-statistic__list">
        <div className="task-statistic__item">
          <Icon28DoneOutline
            fill="var(--accent)"
          />
          <span>{solved} из {total} размечено</span>
        </div>
        <div className="task-statistic__item">
          <Icon28MoneyCircleOutline
            width={28}
            height={28}
            fill="var(--accent)"
          />
          <span>{earned} заработано</span>
        </div>
        <div className="task-statistic__item">
          <Icon28RecentOutline
            fill="var(--accent)"
          />
          <span>
            {`${endTime} ${wordPad(endTime, 'день', 'дня', 'дней')}`} осталось
          </span>
        </div>
      </div>
    </div>
  </Div>
);

TaskStatistic.propTypes = {
  solved: PropTypes.number,
  total: PropTypes.number,
  earned: PropTypes.number,
  endTime: PropTypes.number,
};

TaskStatistic.defaultProps = {
  solved: 0,
  total: 0,
  earned: 0,
  endTime: 0,
};

export default TaskStatistic;

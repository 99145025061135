import * as types from './actionTypes';

const initialState = {
  loaded: false,
  me: {},
  list: {},
  access_token: 'not_allowed',
  rating: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER: {
      return {
        ...state,
        me: action.user,
        loaded: action.access_token !== 'not_allowed',
        access_token: action.access_token,
      };
    }
    case types.LOAD_USER_RATING: {
      const { rating, user } = action;
      return {
        ...state,
        me: {
          ...state.me,
          place: user.place,
          points: user.points,
        },
        rating,
      };
    }
    case types.ADD_USERS: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.users,
        },
      };
    }
    default: return state;
  }
};

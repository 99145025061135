/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import { Radio } from '@vkontakte/vkui';
import { fullSizeImage } from '../services/api';

import '../../styles/quest.scss';
import '../../styles/quest-image.scss';
import { escapeAndFormatString } from '../utils/utils';

const QuestChooseOption = ({ text, image, options, answer, handle }) => {
  const handleImageClick = () => {
    fullSizeImage([image]);
  };

  return (
    <>
      <Div className="quest" style={image ? {} : { paddingBottom: 0 }}>
        <div
          className="quest__title"
          dangerouslySetInnerHTML={{
            __html: escapeAndFormatString(text, {
              links: true,
            }),
          }}
        />

        {image && (
          <div className="quest__box quest__box--choose-option">
            <div className="quest__box-wrap">
              <div
                className="quest-image"
                onClick={handleImageClick}
                style={{ backgroundImage: `url('${image}')` }}
              />
            </div>
          </div>
        )}
      </Div>
      <div className="quest__option">
        {options.map(option => (
          <Radio
            key={option.value}
            name="radio"
            value={option.value}
            checked={answer === option.value}
            onChange={() => handle(option.value)}
          >
            {option.name}
          </Radio>
        ))}
      </div>
    </>
  );
};

QuestChooseOption.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handle: PropTypes.func.isRequired,
  answer: PropTypes.string,
};

QuestChooseOption.defaultProps = {
  text: 'Выберите подходящий вариант',
  image: null,
  options: [
    {
      name: 'Это котик',
      value: '1',
    },
    {
      name: 'Это не котик',
      value: '2',
    },
  ],
  answer: null,
};

export default QuestChooseOption;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import withPlatform from '@vkontakte/vkui/dist/hoc/withPlatform';

import { IOS } from '@vkontakte/vkui';
import * as API from '../services/api';
import RatingItem from './RatingItem';

import '../../styles/rating.scss';
import { addUsers } from '../store/user/actions';

const Rating = ({ top, me, platform }) => {
  const accessToken = useSelector((state) => state.user.access_token);
  const users = useSelector((state) => state.user.list);
  const dispatch = useDispatch();

  useEffect(() => {
    const notResolved = [];
    top.forEach((user) => (!users[user.id] ? notResolved.push(user.id) : null));
    if (notResolved.length > 0) {
      API.getVkProfile(accessToken, notResolved)
        .then((response) => {
          /*
                        After request, you have same array:
                        -> response = [{ @id, @first_name, @last_name, @photo_200, other }]
                        By reduce it will be converted to this object:
                        -> response = {
                        ->     "@id": { @id, @first_name, @last_name, @photo_200, other }
                        -> }
                     */
          const newUsers = response.reduce((a, x, k) => ({
            ...a,
            [x.id]: {
              ...x,
              ...top[k],
            },
          }), {});
          dispatch(addUsers(newUsers));
        });
    }
  }, [users, top, dispatch, accessToken]);

  return (
    <Div className="rating">
      <div className="rating__title">Рейтинг</div>
      <div className="rating__list">
        {top.map((user) => {
          const userData = users[user.id] || {};
          return (
            <RatingItem
              key={user.id}
              position={user.place}
              name={`${userData.first_name} ${userData.last_name}`}
              avatar={userData.photo_200}
              score={user.points}
            />
          );
        })}
        <div
          className={platform === IOS ? 'rating__separator-ios' : 'rating__separator-android'}
        >
          <div className="rating__separator-content" />
        </div>
        <RatingItem
          position={me.place}
          name={`${me.first_name} ${me.last_name}`}
          avatar={me.photo_200}
          score={me.points}
        />
      </div>
    </Div>
  );
};

Rating.propTypes = {
  top: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo_200: PropTypes.string,
  })),
  me: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo_200: PropTypes.string,
    points: PropTypes.string,
    place: PropTypes.string,
  }),
  platform: PropTypes.string.isRequired,
};

Rating.defaultProps = {
  top: [],
};

export default withPlatform(Rating);

import * as types from './actionTypes';
import * as API from '../../services/api';

export const loadTasks = () => async (dispatch) => {
  API.getTasks()
    .then((tasks) => dispatch({
      type: types.LOAD_TASKS,
      tasks,
    }));
};

export const selectTask = (taskId = -1) => {
  return {
    type: types.SELECT_TASK,
    taskId,
  };
};

export const solveTask = (taskId = -1) => {
  return {
    type: types.SOLVE_TASK,
    taskId,
  };
};

export const reduceEarnedPoints = (taskId) => {
  return {
    type: types.REDUCE_EARNED_POINTS,
    taskId,
  };
};

import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Placeholder from '@vkontakte/vkui/dist/components/Placeholder/Placeholder';
import { Icon56CheckCircleOutline } from '@vkontakte/icons';

import { selectTask } from '../store/tasks/actions';

import TaskItem from '../components/TaskItem';

const Home = ({ id, navigator }) => {
  const dispatch = useDispatch();
  const isTaskLoaded = useSelector(state => state.tasks.loaded);
  const tasks = useSelector(state => state.tasks.list);
  const isUserLoaded = useSelector(state => state.user.loaded);

  const openTask = useCallback(
    taskId => {
      dispatch(selectTask(taskId));
      navigator.go('task-about');
    },
    [navigator, dispatch],
  );

  useEffect(() => {
    if (!isUserLoaded && !isTaskLoaded) {
      navigator.showLoader();
    }

    return () => {
      navigator.hideLoader();
    };
  }, [isTaskLoaded, isUserLoaded]);

  return (
    <Panel id={id} theme="white">
      <PanelHeader>Задания</PanelHeader>
      {isTaskLoaded && tasks.length === 0 && (
        <Placeholder icon={<Icon56CheckCircleOutline fill="var(--accent)" />} title="Все задачи решены!">
          Зайдите немного позже
        </Placeholder>
      )}
      {tasks.map(task => (
        <TaskItem
          key={task.id}
          title={task.title}
          description={task.description}
          price={task.price}
          action={() => openTask(task.id)}
        />
      ))}
    </Panel>
  );
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
  navigator: PropTypes.any,
};

export default Home;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

import '../../styles/polygon-label.scss';

const PolygonEditor = ({ title, image, pointRadius, showPoints, lineColor, fillColor, onChangeName, handle }) => {
  // Refs
  const canvas = useRef(null);

  // States
  const [lastClick, setLastClick] = useState([0, 0]);
  const [points, setPoints] = useState([]);
  const [real, setReal] = useState([0, 0]);
  const [label, setLabel] = useState([0, 0]);
  const [labelName, setLabelName] = useState('Полигон');

  const loadImage = () => {
    const ctx = canvas.current.getContext('2d');
    const rect = canvas.current.getBoundingClientRect();
    const img = new Image();

    img.onload = () => {
      canvas.current.height = rect.height;
      canvas.current.width = rect.width;
      // canvas.current.width = img.width - 32;
      ctx.drawImage(img, 0, 0, 375, Math.floor(375 * img.height / img.width));
      setLastClick([0, 0]);
      setPoints([]);
      setLabel([0, 0]);
      setReal([img.width, img.height]);
    };

    img.src = image;
  };

  const makeLabel = (_points) => {
    const centerX = Math.floor(_points.reduce((a, x) => a + x.x, 0) / _points.length);
    const centerY = Math.floor(_points.reduce((a, x) => a + x.y, 0) / _points.length);
    const rect = canvas.current.getBoundingClientRect();

    setLabel([centerX, centerY + rect.top]);

    if (onChangeName) {
      onChangeName(labelName, setLabelName);
    }
  };

  const onTouchStart = (e) => {
    const ctx = canvas.current.getContext('2d');
    const touches = e.changedTouches;
    if (touches.length === 0) return;

    const touch = touches[0];

    const rect = canvas.current.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const touchY = touch.clientY - rect.top;

    if (touchX > 0 && touchX < parseFloat(rect.width) && touchY > 0 && touchY < parseFloat(rect.height)) {
      setLastClick([touchX, touchY]);

      if (lastClick[0] === 0 && lastClick[1] === 0) {
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = lineColor;
        ctx.moveTo(touchX, touchY);
        return;
      }

      setPoints((prev) => [...prev, {
        x: lastClick[0],
        y: lastClick[1],
        realX: Math.floor(lastClick[0] * real[0] / rect.width),
        realY: Math.floor(lastClick[1] * real[1] / rect.height),
      }]);

      if (showPoints) {
        ctx.arc(lastClick[0], lastClick[1], pointRadius, 0, 2 * Math.PI);
      }

      // eslint-disable-next-line no-restricted-properties, max-len
      if (points.length > 0 && Math.sqrt(Math.pow(points[0].x - touchX, 2) + Math.pow(points[0].y - touchY, 2)) <= pointRadius) {
        ctx.lineTo(points[0].x, points[0].y);
        ctx.closePath();
        ctx.fillStyle = fillColor;
        ctx.fill();
        setLastClick([0, 0]);
        ctx.stroke();

        makeLabel([...points, {
          x: lastClick[0],
          y: lastClick[1],
          realX: Math.floor(lastClick[0] * real[0] / rect.width),
          realY: Math.floor(lastClick[1] * real[1] / rect.height),
        }]);
      } else {
        ctx.lineTo(touchX, touchY);
      }

      ctx.stroke();
    }
  };

  const onTouchEnd = () => {
    // console.log('onTouchEnd');
  };

  useEffect(() => {
    if (canvas !== null) {
      const ctx = canvas.current.getContext('2d');
      loadImage(ctx);
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas !== null && (label[0] === 0 && label[1] === 0)) {
      canvas.current.addEventListener('touchstart', onTouchStart, false);
      canvas.current.addEventListener('touchend', onTouchEnd, false);

      return () => {
        canvas.current.removeEventListener('touchstart', onTouchStart);
        canvas.current.removeEventListener('touchend', onTouchEnd);
      };
    }
  }, [lastClick, points, real, label]);

  useEffect(() => {
    handle(label[0] !== 0 && label[1] !== 0 ? ({
      label: labelName,
      points,
    }) : null);
  }, [handle, points, labelName, label]);

  return (
    <>
      <Div className="quest">
        <div className="quest__title">{title}</div>
        <div className="quest__box">
          <div className="quest__box-wrap">
            <canvas
              ref={canvas}
              className="quest-image"
            />
          </div>
        </div>
      </Div>
      <div style={{ position: '' }}>
        {labelName && labelName !== '' &&
        <div
          style={{
            top: label[1],
            left: label[0],
          }}
          className="polygon-label"
          onClick={() => onChangeName(labelName, setLabelName)}
        >
          {labelName}
        </div>}
      </div>
      <Div style={{ display: 'flex', background: 'var(--background_content)' }}>
        <Button
          stretched
          size="l"
          level="secondary"
          onClick={loadImage}
        >
          Очистить
        </Button>
      </Div>
    </>
  );
};

PolygonEditor.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  pointRadius: PropTypes.number,
  lineColor: PropTypes.string,
  fillColor: PropTypes.string,
  onSave: PropTypes.func,
  onChangeName: PropTypes.func,
  showPoints: PropTypes.bool,
  handle: PropTypes.func.isRequired,
};

PolygonEditor.defaultProps = {
  title: 'Выделите котика',
  image: 'https://picsum.photos/200/300?random=7',
  pointRadius: 20,
  lineColor: '#71aaeb',
  fillColor: 'rgba(113, 170, 235, 0.3)',
  onSave: null,
  onChangeName: null,
  showPoints: false,
};

export default PolygonEditor;
